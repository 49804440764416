import dayjs from 'dayjs';

// Qualification mapping for each FM.
const qualificationsMap = {
    FM1: ["FM1", "FM2"],
    FM2: ["FM1", "FM2"],
    FM3: ["FM1", "FM2", "FM3"],
    FM4: ["FM1", "FM2", "FM3", "FM4"],
    FM5: ["FM5"],
    FM6: ["FM5", "FM6"],
  };
  

function FMCheck(material, qualification) {
    // Get the allowed qualifications for the specified material.
    if (!material) {
        return true;
    }
    const allowedMaterials = qualificationsMap[qualification];

    if (!allowedMaterials) {
        return false;
    }

    return allowedMaterials.includes(material);
}
  

export function filterWpsOptions(hegesztes, wpsList, projectSettings) {
    return wpsList.filter(wps => {
  
        // If check_weld_position is true, check if the hegesztes position is included in the WPS positions
        if (projectSettings.check_weld_position) {
            if (
                hegesztes.position &&
                wps.positions &&
                wps.positions.length > 0 &&
                !wps.positions.includes(hegesztes.position)
            ) {
                console.log(`Blocked by position check: hegesztes.position=${hegesztes.position}, wps.positions=${wps.positions}`);
                return false;
            }
        }
  
        // Check PT: P can weld P, T can weld T, P/T can weld both
        if (hegesztes.pt){
            if (wps.pt !== 'P/T' && wps.pt !== hegesztes.pt) {
                console.log(`Blocked by PT check: hegesztes.pt=${hegesztes.pt}, wps.pt=${wps.pt}`);
                return false;
            }
        }

        // Parse numerical values to ensure correct comparisons
        const hegesztesD = parseFloat(hegesztes.D);
        const hegesztesV = parseFloat(hegesztes.v);
  
        // Check if the diameter (D) of the hegesztes is within the WPS range
        if (wps.lower_d !== null && wps.lower_d !== undefined && !isNaN(wps.lower_d)) {
            if (hegesztesD < wps.lower_d) {
                console.log(`Blocked by lower_d check: hegesztesD=${hegesztesD}, wps.lower_d=${wps.lower_d}`);
                return false;
            }
        }
        if (wps.upper_d !== null && wps.upper_d !== undefined && !isNaN(wps.upper_d)) {
            if (hegesztesD > wps.upper_d) {
                console.log(`Blocked by upper_d check: hegesztesD=${hegesztesD}, wps.upper_d=${wps.upper_d}`);
                return false;
            }
        }
  
        // Check if the wall thickness (v) of the hegesztes is within the WPS range
        if (wps.lower_v !== null && wps.lower_v !== undefined && !isNaN(wps.lower_v)) {
            if (hegesztesV < wps.lower_v) {
                console.log(`Blocked by lower_v check: hegesztesV=${hegesztesV}, wps.lower_v=${wps.lower_v}`);
                return false;
            }
        }
        if (wps.upper_v !== null && wps.upper_v !== undefined && !isNaN(wps.upper_v)) {
            if (hegesztesV > wps.upper_v) {
                console.log(`Blocked by upper_v check: hegesztesV=${hegesztesV}, wps.upper_v=${wps.upper_v}`);
                return false;
            }
        }
  
        // If all checks pass, this WPS is applicable
        return true;
    });
  }


  export function filterWeldersOptions(wps, workers, qualifications, v, D) {

    console.log('wps:', wps);
    console.log('workers:', workers);
    console.log('qualifications:', qualifications);
    console.log('v:', v);
    console.log('D:', D);

    const now = dayjs(); // Current date

    return workers.filter(worker => {
        console.log(`Checking worker: ${worker.value}, name: ${worker.label}`);

        // Get all qualifications for the current worker
        const workerQualifications = qualifications.filter(q => q.worker_id === worker.value);
        console.log(`Worker qualifications:`, workerQualifications);

        // Check if any qualification meets all the criteria
        const isQualified = workerQualifications.some(q => {
            console.log(`Checking qualification:`, q);

            // Check if the qualification is currently valid
            const validFrom = dayjs(q.valid_from);
            const validTo = dayjs(q.valid_to);
            if (!now.isAfter(validFrom)) {
                console.log(`Qualification is not valid yet. valid_from: ${q.valid_from}`);
                return false;
            }
            if (!now.isBefore(validTo)) {
                console.log(`Qualification is expired. valid_to: ${q.valid_to}`);
                return false;
            }

            // If wps is null, qualification is valid by default
            if (wps === null) {
                console.log(`WPS is null; qualification is valid.`);
                return true;
            }

            // Check weld_material match FM check
            if (!FMCheck(wps.weld_material, q.weld_material)) {
                console.log(`FM check failed. WPS weld_material: ${wps.weld_material}, Qualification weld_material: ${q.weld_material}`);
                return false;
            }

            // // Check weld_type match BW/FW
            // if (!q.weld_types.includes(wps.weld_type)) {
            //     console.log(`Weld type mismatch. WPS weld_type: ${wps.weld_type}, Qualification weld_types: ${q.weld_types}`);
            //     return false;
            // }

            // Check pt match
            if (q.pt !== wps.pt) {
                console.log(`PT mismatch. WPS pt: ${wps.pt}, Qualification pt: ${q.pt}`);
                return false;
            }

            // Check method_id match
            if (q.method_id !== wps.method_id) {
                console.log(`Method ID mismatch. WPS method_id: ${wps.method_id}, Qualification method_id: ${q.method_id}`);
                return false;
            }

            // Check V range
            if (q.lower_v != null && v < q.lower_v) {
                console.log(`V range lower bound mismatch. v: ${v}, Qualification lower_v: ${q.lower_v}`);
                return false;
            }
            if (q.upper_v != null && v > q.upper_v) {
                console.log(`V range upper bound mismatch. v: ${v}, Qualification upper_v: ${q.upper_v}`);
                return false;
            }

            // Check D range
            if (q.lower_d != null && D < q.lower_d) {
                console.log(`D range lower bound mismatch. D: ${D}, Qualification lower_d: ${q.lower_d}`);
                return false;
            }
            if (q.upper_d != null && D > q.upper_d) {
                console.log(`D range upper bound mismatch. D: ${D}, Qualification upper_d: ${q.upper_d}`);
                return false;
            }

            // All criteria met
            console.log(`Qualification passed for worker ${worker.value}`);
            return true;
        });

        if (!isQualified) {
            console.log(`Worker ${worker.value} is not qualified.`);
        }

        return isQualified;
    });
}

