import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faClone } from '@fortawesome/free-solid-svg-icons';
import { Button, Badge, Tooltip } from '@mantine/core';
import LoadingWrapper from '../../../Atoms/Loading';
import { Loader } from '@mantine/core';
import { GlobalContext } from '../../../Home/GlobalContext';
import { filterWpsOptions } from '../../../../util/weldChecks';
import AgGridSingleSelectEditor from '../../../../util/AgGridSingleSelectEditor';

const HegesztesDataEntryTable = ({ selectedSheet }) => {
    const [rowData, setRowData] = useState([]);
    const [originalRowData, setOriginalRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [wpsData, setWpsData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;
    const { projectIDGlobal, projectSettings } = useContext(GlobalContext);

    const fieldHeaderMap = {
        id: 'Mentett',
        sorszam: 'Sorszám',
        tipus: 'Típus',
        D: 'Külső átmérő',
        v: 'Falvastagság',
        wps_id: 'WPS',
        position: 'Pozíció',
        munkaora: 'Munkaóra',
        pt: 'P/T'
    };

    const validationRules = {
        sorszam: 'number',
        tipus: 'text',
        D: 'number',
        v: 'number',
    };

    // Fetch Hegesztes data and sheet material
    useEffect(() => {
        setLoading(true);

        const fetchHegesztesData = fetch(`${apiUrl}/api/get_table?sheet_id=${selectedSheet.id}&table_name=hegesztes`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                console.log('Hegesztes data:', parsedData);
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                setRowData(parsedData);
                // Save original data for comparison as deep copy
                setOriginalRowData(JSON.parse(JSON.stringify(parsedData)));
            });

        Promise.all([fetchHegesztesData])
            .catch(() => {})
            .finally(() => setLoading(false));
    }, [selectedSheet.id, apiUrl]);

    // Fetch WPS and Materials data
    useEffect(() => {
        setLoading(true);
        const fetchWpsData = fetch(`${apiUrl}/api/wps?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = data;
                console.log('WPS data:', parsedData);
                setWpsData(parsedData);
            });

        Promise.all([fetchWpsData])
            .catch(() => {})
            .finally(() => setLoading(false));
    }, [projectIDGlobal, apiUrl]);

    // Function to check if a row has been modified
    const isRowModified = useCallback((currentRow) => {
        if (currentRow.id === null) return false;
        console.log(`Checking row with ID: ${currentRow.id}`);

        const originalRow = originalRowData.find(row => row.id === currentRow.id);
        if (!originalRow) return false;

        const fieldsToCompare = ['sorszam', 'tipus', 'D', 'v', 'wps_id', 'position', 'munkaora', 'pt'];
        for (let field of fieldsToCompare) {
            const originalValue = originalRow[field];
            const currentValue = currentRow[field];

            // Handle type differences and trimming if necessary
            if (typeof originalValue === 'number' && typeof currentValue === 'number') {
                if (originalValue !== currentValue) {
                    console.log(`Field "${field}" modified: ${originalValue} -> ${currentValue}`);
                    return true;
                }
            } else {
                if (String(originalValue).trim() !== String(currentValue).trim()) {
                    console.log(`Field "${field}" modified: "${originalValue}" -> "${currentValue}"`);
                    return true;
                }
            }
        }

        return false;
    }, [originalRowData]);

    // Validation function
    const validateRows = (rows) => {
        let isValid = true;
        let missingFields = [];

        console.log('in validateRows');
        console.log('rows:', rows);
        
        rows.forEach((row) => {
            Object.keys(validationRules).forEach((key) => {
                const value = row[key];

                if (validationRules[key] === 'text') {
                    if (typeof value !== 'string' || value.trim() === '') {
                        isValid = false;
                        missingFields.push(fieldHeaderMap[key]);
                    }
                } else if (validationRules[key] === 'number') {
                    if (isNaN(value) || value === null) {
                        isValid = false;
                        missingFields.push(fieldHeaderMap[key]);
                    }
                }
            });
        });
    
        if (!isValid) {
            setTooltipContent(
                `Please fill out the required fields: ${missingFields.join(', ')}`
            );
        } else {
            setTooltipContent('');
        }
    
        return isValid;
    };

    // Handle data changes for validation
    const handleRowDataChanged = () => {
        if (gridRef.current && gridRef.current.api) {
            const gridApi = gridRef.current.api;
            const allData = [];
            gridApi.forEachNode((node) => allData.push(node.data));
            setRowData(allData); // Synchronize state with grid data
            const isValid = validateRows(allData);
            setSaveDisabled(!isValid);
        }
    };

    // Function to check if a row is modified and return badge accordingly
    const rowCellRenderer = useCallback((params) => {
        if (params.value === null) {
            return <Badge color='red'>Új</Badge>; // New row
        }
        return isRowModified(params.data) ? <Badge color='yellow'>Módosítva</Badge> : <Badge color='green'>Igen</Badge>;
    }, [isRowModified]);

    // Function to toggle checkboxes (if any)
    const toggleCheckbox = (params) => {
        const newValue = !params.value;
        params.node.setDataValue(params.colDef.field, newValue);
        // Implement any additional logic if needed
        handleRowDataChanged();
    };

    // Set 'munkaora' and 'id' to null based on certain changes
    const setMunkaoraToNull = (params) => {
        const rowNode = params.api.getRowNode(params.node.id);
        rowNode.setDataValue('munkaora', null);
        handleRowDataChanged();
    };

    // Find the lowest available 'sorszam'
    const findLowestAvailableSorszam = (api) => {
        let sorszam = 1;
        const existingSorszam = new Set();
        api.forEachNode((node) => {
            existingSorszam.add(node.data.sorszam);
        });
        while (existingSorszam.has(sorszam)) {
            sorszam++;
        }
        return sorszam;
    };

    // Clone a row
    const cloneRow = (params) => {
        params.api.applyTransaction({
            add: [
                {
                    ...params.data,
                    sorszam: findLowestAvailableSorszam(params.api),
                    id: null,
                    munkaora: null,
                    wps_id: null,
                    position: null,
                    pt: null
                }
            ]
        });
        handleRowDataChanged();
    };

    // Delete a row
    const deleteRow = (params) => {
        params.api.applyTransaction({ remove: [params.data] });
        handleRowDataChanged();
    };

    const gridRef = useRef(null);

    // Save data function
    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowDataToSave = [];
        gridApi.forEachNode(node => {
            const row = {
                ...node.data,
                position: node.data.position !== undefined ? node.data.position : null,
                pt: node.data.pt !== undefined ? node.data.pt : null
            };
            // Replace empty strings with null
            Object.keys(row).forEach(key => {
                if (row[key] === '') {
                    row[key] = null;
                }
            });
            rowDataToSave.push(row);
        });
    
        console.log('rowData:', rowDataToSave);
        setLoading(true);
    
        let requestBody = {
            data: rowDataToSave,
            sheet_id: parseInt(selectedSheet.id)
        };
    
        fetch(`${apiUrl}/api/calculate_hegesztes_normhours`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const parsedData = data;
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                setRowData(parsedData);
                // Save original data for comparison as deep copy
                setOriginalRowData(JSON.parse(JSON.stringify(parsedData)));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error); // Log the error for debugging
                alert(
                    'Nem sikerült a munkaóra számolás és mentés. Ellenőrizze a beírt adatokat és próbálja újra.'
                );
                setLoading(false);
            });
    };

    // Add a new row
    const newRow = () => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({
            add: [
                {
                    sorszam: findLowestAvailableSorszam(gridApi),
                    tipus: '',
                    D: null,
                    v: null,
                    wps_id: null,
                    position: null,
                    pt: null,
                    munkaora: null,
                    id: null
                }
            ]
        }); 
        handleRowDataChanged();
    };

    // Define column definitions with custom AgGridSingleSelectEditor
    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    <button
                        onClick={() => cloneRow(params)}
                        style={{ border: 'none', background: 'none' }}
                    >
                        <FontAwesomeIcon icon={faClone} style={{ fontSize: '1rem' }} />
                    </button>
                    <button
                        onClick={() => deleteRow(params)}
                        style={{ border: 'none', background: 'none' }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        {
            field: 'id',
            headerName: fieldHeaderMap['id'],
            cellRenderer: rowCellRenderer
        },
        {
            field: 'sorszam',
            headerName: fieldHeaderMap['sorszam'],
            editable: true,
            cellClassRules: {
                'highlight-cell': params => !params.value
            }
        },
        {
            field: 'tipus',
            headerName: fieldHeaderMap['tipus'],
            editable: true,
            cellEditor: AgGridSingleSelectEditor,
            cellEditorParams: {
                options: [
                    { value: 'Körvarrat', label: 'Körvarrat' },
                    { value: 'Sarokvarrat', label: 'Sarokvarrat' },
                    { value: 'Szegmens', label: 'Szegmens' },
                    { value: 'Csőbeültetés', label: 'Csőbeültetés' },
                    { value: 'Weldolet', label: 'Weldolet' },
                    { value: 'Köszörülés', label: 'Köszörülés' },
                    { value: 'Vágás', label: 'Vágás' }
                ]
            },
            cellClassRules: {
                'highlight-cell': params => !params.value
            },
            onCellValueChanged: (params) => setMunkaoraToNull(params)
        },
        {
            field: 'D',
            headerName: fieldHeaderMap['D'],
            editable: true,
            cellClassRules: {
                'highlight-cell': params => isNaN(params.value) || params.value === null
            },
            onCellValueChanged: (params) => setMunkaoraToNull(params)
        },
        {
            field: 'v',
            headerName: fieldHeaderMap['v'],
            editable: true,
            cellClassRules: {
                'highlight-cell': params => isNaN(params.value) || params.value === null
            },
            onCellValueChanged: (params) => setMunkaoraToNull(params)
        },
        {
            field: 'wps_id',
            headerName: fieldHeaderMap['wps_id'],
            editable: true,
            cellEditor: AgGridSingleSelectEditor,
            cellEditorParams: (params) => {
                // Filter your WPS options as needed:
                const filteredOptions = filterWpsOptions(
                    params.data,
                    wpsData,
                    projectSettings
                );

                // Map to { value, label } and sort alphabetically by label
                const sortedOptions = filteredOptions
                    .map(wps => ({ value: wps.id, label: wps.name }))
                    .sort((a, b) => a.label.localeCompare(b.label));

                // Add an empty option for null selection
                return {
                    options: [{ value: null, label: '' }, ...sortedOptions]
                };
            },
            // Format the displayed value (convert wps_id -> wps.name)
            valueFormatter: params => {
                if (
                    params.value === null ||
                    params.value === undefined ||
                    params.value === ''
                ) {
                    return '';
                }
                const wps = wpsData.find(
                    wps => wps.id === params.value
                );
                return wps ? wps.name : params.value;
            },
            // Use valueSetter to handle numeric conversion
            valueSetter: (params) => {
                if (params.newValue === null || params.newValue === '') {
                    params.data.wps_id = null;
                } else {
                    params.data.wps_id = parseInt(params.newValue, 10);
                }
                return true;
            },
            onCellValueChanged: (params) => {
                // When a new WPS is selected, if the hegesztes.pt is null,
                // set it to the selected WPS's pt.
                if (params.data.wps_id !== null) {
                    const selectedWps = wpsData.find(wps => wps.id === params.data.wps_id);
                    if (selectedWps && params.data.pt === null) {
                        params.data.pt = selectedWps.pt;
                        params.api.refreshCells({ rowNodes: [params.node], columns: ['pt'] });
                    }
                }
            }
        },
        {
            field: 'position',
            headerName: fieldHeaderMap['position'],
            editable: true,
            cellEditor: AgGridSingleSelectEditor,
            cellEditorParams: {
                options: [
                    { value: null, label: '' },
                    { value: 'PA', label: 'PA' },
                    { value: 'PB', label: 'PB' },
                    { value: 'PD', label: 'PD' },
                    { value: 'PH', label: 'PH' }
                ]
            },
            valueFormatter: params => {
                return params.value === '' || params.value === null ? '' : params.value;
            },
            valueSetter: (params) => {
                // If the user clears selection or chooses a new one
                params.data.position = params.newValue === null || params.newValue === '' ? null : params.newValue;
                return true;
            }
        },
        {
            field: 'pt',
            headerName: fieldHeaderMap['pt'],
            editable: true,
            cellEditor: AgGridSingleSelectEditor,
            cellEditorParams: {
                options: [
                    { value: null, label: '' },
                    { value: 'P', label: 'P' },
                    { value: 'T', label: 'T' }
                ]
            },
            valueFormatter: params => {
                return params.value === '' || params.value === null ? '' : params.value;
            },
            valueSetter: (params) => {
                // If the user clears selection or chooses a new one
                params.data.pt = params.newValue === null || params.newValue === '' ? null : params.newValue;
                return true;
            },
            onCellValueChanged: (params) => {
                // When the hegesztes.pt value changes, if a WPS is already selected,
                // check that its pt matches the new value. If not, clear the wps_id.
                if (params.data.wps_id !== null) {
                    const selectedWps = wpsData.find(wps => wps.id === params.data.wps_id);
                    if (selectedWps && params.data.pt !== selectedWps.pt) {
                        params.data.wps_id = null;
                        params.api.refreshCells({ rowNodes: [params.node], columns: ['wps_id'] });
                    }
                }
            }
        },
        {
            field: 'munkaora',
            headerName: fieldHeaderMap['munkaora'],
            editable: true
        },
    ], [wpsData, projectSettings, isRowModified]);

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                .highlight-cell {
                    background-color: #ffcccc;  /* Highlight color for invalid cells */
                }
                `}
            </style>
            <div style={{ display: 'flex', marginBottom: '5px' }}>
                <Tooltip label={tooltipContent} disabled={!tooltipContent}>
                    <div>
                        <Button
                            onClick={saveData}
                            style={{
                                padding: '10px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                marginRight: '5px'
                            }}
                            disabled={saveDisabled}  // Disable save button if validation fails
                        >
                            Munkaóra számolása és mentés
                        </Button>
                    </div>
                </Tooltip>

                <Button
                    onClick={newRow}
                    style={{
                        padding: '10px',
                        marginTop: '10px',
                        marginBottom: '5px'
                    }}
                >
                    Új sor hozzáadása
                </Button>
            </div>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        onCellValueChanged={handleRowDataChanged}  // Updated handler
                        stopEditingWhenCellsLoseFocus={true}
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                            minWidth: 100,
                        }}
                    />
                </LoadingWrapper>
            </div>
        </>
    );
};

export default HegesztesDataEntryTable;
